/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MathProcessor = {
  basetype?: MathProcessor.basetype;
  math_expression: string;
};

export namespace MathProcessor {
  export enum basetype {
    MATH_PROC = "math_proc",
  }
}
